@import "../../styles/utils";

.correct-container {
  margin-top: -140px;
}

.text-box-container {
  margin: auto;
  min-width: auto;
  max-width: 800px;
  border-radius: 6px;
  border: 1px solid white;
  background-color: white;
  box-shadow: 0 20px 40px rgba(0, 0, 0, .2);
}

.text-box {
  display: block;
  position: relative;
  text-align: left;
  min-height: 250px;
  font-size: 17px;
  line-height: 24px;
  padding: 20px;

  &:focus {
    outline: none;
  }
  ::placeholder {
    color: deeppink;
    font-size: 5rem;
    text-transform: uppercase;
  }

  p {
    margin: 0;
  }
}

.statistics {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  border-top: 1px solid lightgrey;
}

.statistics-issues {
  display: flex;
  align-items: center;
}

.statistics-counts {
  display: flex;
  color: grey;
  font-size: 1.2rem;

  div {
    margin-left: .5rem;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 500px;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.submit-button {
  padding: 9px 12px;
  color: white;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 4px;
  border: 1px solid #2196F3;
  text-transform: uppercase;
  background-color: #2196F3;
  transition: all .1s linear;
  box-shadow: 0 4px 8px 1px rgba(0, 0, 0, .2);

  &:hover {
    border-color: #107ed2;
    background-color: #107ed2;
  }

  &:active {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &.red {
    border-color: $red;
    background-color: $red;
  }

  &.blue {
    border-color: $blue;
    background-color: $blue;
  }

  &.green {
    border-color: #107ed2;
    background-color: #107ed2;
  }

  &.final {
    border-color: cadetblue;
    background-color: cadetblue;
  }
}

.bottom-text {
  font-size: 14px;
  padding-top: 7px;
  text-align: center;
  font-style: oblique;
  color: grey;
  column-count: 2;
  grid-column-gap: 1px;
  width: 500px;
  column-rule-style: solid;
  column-rule-color: black;
  column-rule: 1px dotted grey;
  column-span: all;
  padding-bottom: 7px;
  border-bottom: 1px dotted grey;
  border-top: 1px dotted grey;
  user-select: none;
  position: relative;

  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}



.copyright-text {
  margin-top: 30px;
  font-size: 14px;
  color: grey;
}


@keyframes underline {
  0%   {width: 0}
  100% {width: 100%}
}

@mixin underline($color) {
  &:after {
    opacity: 1;
    background-color: rgba($color, .8);
  }

  &:hover {
    cursor: default;
    &:after {
      background-color: rgba($color, 1);
    }
  }
}

.text-word {
  position: relative;
  display: inline-block;

  &:hover {
    &:after {
      -webkit-box-shadow: 0px 5px 10px 1px rgba(0,0,0,0.3);
      -moz-box-shadow: 0px 5px 10px 1px rgba(0,0,0,0.3);
      box-shadow: 0px 5px 10px 1px rgba(0,0,0,0.3);
    }

  }


  &:after {
    opacity: 0;
    position: absolute;
    content: "";
    width: 100%;
    bottom: -2px;
    left: 0;
    height: 3px;
    border: 0 !important;
    background-color: black;
    animation-name: underline;
    animation-duration: .2s;
    transition: all .1s linear;

  }

  &.spelling-mistake {
    @include underline(#ff0000)
  }

  &.grammar-mistake-sure {
    @include underline(#8d18ff)
  }

  &.grammar-mistake-unsure {
    @include underline(#ffd500)
  }

  &.style {
    @include underline(#3234a8)
  }

  &.analyze-morphology {
    @include underline(#34c6eb)
  }
}
