@import "../../styles/utils";

@mixin statistic($color) {
  background-color: rgba($color, .2);

  .statisticNumber {
    background-color: $color;
  }
  .statisticText {
    color: $color;
  }
}


.statistic {
  display: flex;
  align-items: center;

  padding: .4rem;

  font-size: 1.2rem;
  border-radius: .2rem;

  &.disabled {
    @include statistic(darkgrey);
  }

  &.green {
    @include statistic($green);
  }

  &.red {
    @include statistic($red);
  }

  &.purple {
    @include statistic($purple);
  }

  &.blue {
    @include statistic($blue);
  }

  &.pink {
    @include statistic($pink);
  }

  &.orange {
    @include statistic($orange);
  }


  .statisticNumber {
    width: 2rem;
    height: 2rem;

    color: white;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
    border-radius: .2rem;
  }

  .statisticText {
    margin-left: .6rem;
    margin-right: .2rem;
    line-height: 1.4rem;
  }
}

