$red: #EF5350;
$purple: #7E57C2;
$orange: #FFA726;
$blue: #107ed2;
$pink: #EC407A;
$green: #66BB6A;

.mb-12 { margin-bottom: 1.2rem; }
.mb-16 { margin-bottom: 1.6rem; }
.mb-24 { margin-bottom: 2.4rem; }
.mb-32 { margin-bottom: 3.2rem; }
.mb-40 { margin-bottom: 4rem; }
.mb-48 { margin-bottom: 4.8rem; }
.mb-58 { margin-bottom: 5.8rem; }
.mb-94 { margin-bottom: 9.4rem; }

.mt-16 { margin-top: 1.6rem; }
.mt-24 { margin-top: 2.4rem; }
.mt-32 { margin-top: 3.4rem; }
.mt-40 { margin-top: 4rem; }
.mt-48 { margin-top: 4.8rem; }
.mt-58 { margin-top: 5.8rem; }
.mt-94 { margin-top: 9.4rem; }
.mt-16-tablet {@media (max-width: 768px) { margin-top: 1.6rem;}}

.ml-12 { margin-left: 1.2rem; }
.ml-16 { margin-left: 1.6rem; }
.ml-24 { margin-left: 2.4rem; }
.ml-32 { margin-left: 3.4rem; }
.ml-40 { margin-left: 4rem; }
.ml-48 { margin-left: 4.8rem; }
.ml-auto { margin-left: auto; }
.ml-12-tablet {@media (max-width: 768px) { margin-left: 1.2rem;}}

.mr-16 { margin-right: 1.6rem; }
.mr-24 { margin-right: 2.4rem; }
.mr-32 { margin-right: 3.4rem; }
.mr-40 { margin-right: 4rem; }
.mr-48 { margin-right: 4.8rem; }
.mr-12-tablet {@media (max-width: 768px) { margin-right: 1.2rem;}}

.pl-16 { padding-left: 1.6rem; }
.pl-24 { padding-left: 2.4rem; }
.pl-32 { padding-left: 3.4rem; }
.pl-40 { padding-left: 4rem; }
.pl-48 { padding-left: 4.8rem; }

.pr-16 { padding-right: 1.6rem; }
.pr-24 { padding-right: 2.4rem; }
.pr-32 { padding-right: 3.4rem; }
.pr-40 { padding-right: 4rem; }
.pr-48 { padding-right: 4.8rem; }

.pb-16 { padding-bottom: 1.6rem; }
.pb-24 { padding-bottom: 2.4rem; }
.pb-32 { padding-bottom: 3.4rem; }
.pb-40 { padding-bottom: 4rem; }
.pb-48 { padding-bottom: 4.8rem; }

.p-16 { padding: 1.6rem; }
.p-24 { padding: 2.4rem; }
.p-32 { padding: 3.4rem; }
.p-40 { padding: 4rem; }
.p-48 { padding: 4.8rem; }
.p-24-tablet {@media (max-width: 768px) { padding: 2.4rem;}}

.cursor-pointer { cursor: pointer }

.rotate-180 { transform: rotate(180deg) }

.mb-0-tablet {@media (max-width: 768px) { margin-bottom: 0rem;}}
.mb-32-tablet {
  @media (max-width: 768px) {
    margin-bottom: 3.2rem;
  }
}

@mixin smaller-phone {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin phone {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 768px) {
    @content;
  }
}
