.login-page {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 100vh;
  background-color: rgba(#2196F3, .1);
}

.login-title {
  color: grey;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 38px;
}

.login-button {
  margin-top: 12px;
}

.login-box {
  display: flex;
  flex-direction: column;

  width: 320px;
  padding: 30px;

  border-radius: 4px;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16),
  0 3px 6px rgba(0,0,0,0.23);

  input {
    height: 24px;

    padding: 4px 12px;
    margin-bottom: 12px;

    outline: none;
    border-radius: 4px;
    border: 1px solid lightgray;

    &:focus {
      border-color: darkgray;
    }
  }
}