html {
  font-size: 10px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //background-color: #d5e3e2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  margin-top: -100px;
}

.primary-button {
  padding: 8px 24px;

  color: white;
  outline: none;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;

  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #2196F3;
  background-color: #2196F3;
  transition: all .1s linear;
  box-shadow: 0 8px 15px rgba(#2196F3, .5);

&:active {
   box-shadow: 0 2px 4px rgba(#2196F3, .5);
 }
}

.ql-toolbar {
  border: none !important;
}

.ql-container {
  border: none !important;
}

.ql-editor {
  min-height: 25rem !important;
}

.draft-editor-editor {
  font-size: 14px;
  .DraftEditor-editorContainer {
    padding: 0 1rem;
    height: 25rem !important;
  }
}
