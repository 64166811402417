.header {
  height: 250px;
  background-color: #2196F3;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  height: 40px;
  padding: 20px 40px;
  text-transform: capitalize;
}

.logo {
  font-size: 23px;
  font-weight: bold;
  text-transform: uppercase;
}

.nav {
  font-weight: bolder;
}

.nav-item {
  cursor: pointer;
}