@import "../../styles/utils";

.mistake {
  position: relative;

  &:before {
    position: absolute;
    bottom: -.2rem;
    left: 0;

    content: "";
    height: calc(100% + .1rem);
    width: 100%;

    background-color: rgba($red, .15);

    animation-name: appear;
    animation-duration: .5s;
  }

  &:after {

    position: absolute;
    bottom: -.2rem;
    left: 0;

    content: "";
    width: 100%;
    height: .2rem;

    background-color: $red;

    animation-name: appear;
    animation-duration: .5s;
  }


  &.grammarSure {
    &:before {
      background-color: rgba(#ff41a5, .15);
    }
    &:after {
      background-color: #ff41a5;
    }
  }

  &.grammar {
    &:before {
      background-color: rgba(#ffd322, .15);
    }
    &:after {
      background-color: #ffd322;
    }
  }

  &.style {
    &:before {
      background-color: rgba(dodgerblue, .15);
    }
    &:after {
      background-color: dodgerblue;
    }
  }
}


@keyframes appear {
  from {opacity: 0}
  to {opacity: 1}
}
