@import "../../styles/utils";

.suggestionsContainer {
    z-index: 9;
    width: 22rem;
    overflow: hidden;
    border-radius: .4rem;
    box-shadow: 0 3px 6px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.1);

    animation-name: appear;
    animation-duration: .1s;
    background-color: white;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .6rem .8rem;

        color: white;
        font-size: 1.2rem;
        font-weight: bold;
        background-color: $red;

        .closeIcon {
            cursor: pointer;
            font-size: 1.4rem;
            font-weight: bolder;
        }
    }

    .suggestions {
        display: flex;
        flex-wrap: wrap;

        padding: .6rem;

        .suggestion {
            cursor: pointer;
            font-size: 1.4rem;

            margin: .3rem;
            padding: .2rem .4rem;

            border-radius: .4rem;
            background-color: rgba($green, .5);
            transition: all .1s linear;

            &:hover {
                background-color: rgba($green, .75);
            }
        }
    }

    &.grammarSure {
        .header {
            background-color: #ff41a5;
        }
    }

    &.grammar {
        .header {
            background-color: #ffd322;
        }
    }

    &.style {
        .header {
            background-color: dodgerblue;
        }
    }
}

@keyframes appear {
    from {opacity: 0}
    to {opacity: 1}
}

